/* custom-fonts.css */

@font-face {
    font-family: 'Caveat';
    src: url('./assets/fonts/Caveat-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Salsa';
    src: url('./assets/fonts/Salsa-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Genos';
    src: url('./assets/fonts/Genos-Italic-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'OnBase';
    src: url('./assets/fonts/Onbase.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  

  @font-face {
    font-family: 'Volianchy';
    src: url('./assets/fonts/Volianchy.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'LeagueSpartan';
    src: url('./assets/fonts/LeagueSpartan-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

  }
  