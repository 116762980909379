/* styles.css */

/* Import your custom fonts */
@import './customFonts.css';

/* ... rest of your CSS rules */

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* styles.css */
.hide-number-buttons::-webkit-inner-spin-button,
.hide-number-buttons::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hide-number-buttons {
  -moz-appearance: textfield;
}
/* Custom CSS */
.custom-date-picker::-webkit-calendar-picker-indicator {
  color: #6B7280; /* Tailwind gray-500 */
  background-color: #6B7280;
}

.extra-pdf-spacing span {
  padding-right: 0.5em; /* Adjust the value as needed for PDF output */
}

.destination-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns children (like dropdown) to the start of the container */
}

/* Absolute position for the dropdown, relative to its parent */
.destination-container .absolute {
  position: absolute;
  top: 100%; /* Positions right below the input bar */
  left: 0;   /* Aligns to the left of the container */
  width: 100%; /* Optional: to match the width of the input bar */
}

/* Media Queries for different screen sizes */
@media (max-width: 768px) {
  .destination-container .absolute {
    /* Adjust position for smaller screens */
    left: 0;
  }
}

.custom-date-picker::-webkit-calendar-picker-indicator {
  color: #6B7280; /* Tailwind gray-500 */
  background-color: #6B7280;
}
/* Styles for recommendation dropdowns */
.recommendation-container {
  position: absolute;
  top: 100%; /* Position it right below the input field */
  left: 0;
  width: 100%; /* Full width of the parent input container */
  background: white;
  /* border: 1px solid #ddd; Subtle border */
  border-radius: 0.25rem; /* Slightly rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  z-index: 50; /* Ensure it stacks above other content */
  max-height: 300px; /* Maximum height before scrolling */
  overflow-y: auto; /* Enable scroll for longer lists */
}

.recommendation-item {
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background-color 0.2s ease;
  
}

.recommendation-item:hover {
  background-color: #f0f0f0;
}

/* Adjust other styles as needed to match your application's theme */
/* Add this to your CSS file */
.whiteTextPagination .MuiPaginationItem-root {
  color: white !important; /* Ensuring override */
}

/* Styles for the slider container */
.horizontal-slider {
  width: 100%;
  height: 25px;
  position: relative;
}

/* Styles for the track of the slider */
.example-track {
  background: #ddd;
  height: 4px;
  position: absolute;
  border-radius: 2px;
  top: 50%;
  transform: translateY(-50%);
}

/* Styles for the thumbs */
/* Styles for the thumbs */
.example-thumb {
  height: 18px;
  width: 18px;
  background-color: #3a8589; /* Change this color to match your design */
  border-radius: 50%;
  cursor: grab;
  border: 1px solid #fff;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Styles for the number below the thumb */
.thumb-value {
  position: absolute;
  top: 20px; /* Adjust this value as needed to position the number below the thumb */
  font-size: 12px;
  color: white;
  white-space: nowrap;
}


/* Hover state for thumbs */
.example-thumb:hover {
  background-color: #2b676b; /* Darker shade for hover state */
}

/* Active/dragging state for thumbs */
.example-thumb:active {
  cursor: grabbing;
}

.popup-backdrop {
  position: fixed; /* Cover the entire viewport */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent backdrop */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* High z-index to ensure it's on top */
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  z-index: 1001; /* Higher than backdrop to ensure it's on top */
  position: relative; /* Establish a new stacking context */
  overflow-y: auto;
  max-height: 80vh; /* Viewport-relative units */

}

.bg-primary {
  background-color: #121212; /* Using the value from cards.Primary */
}

.bg-secondary {
  background-color: #424242; /* Using the value from cards.Secondary */
}

.bg-tertiary {
  background-color: #616161; /* Using the value from cards.Tertiary */
}

.bg-quaternary {
  background-color: #757575; /* Using the value from cards.Quaternary */
}

.text-heading, .text-subheading, .text-h1, .text-h2 {
  color: #FFFFFF; /* Using the value from text.Heading */
}

.text-h3 {
  color: #E8E8E8; /* Using the value from text.Primary */
}

.text-h4 {
  color: #D2D2D2; /* Using the value from text.Paragraph */
}

.text-h5 {
  color: #BCBCBC; /* Using the value from text.Secondary */
}


/* styles.css */
.shadow-135deg {
  box-shadow: 4px 4px 6px rgb(0, 0, 0); /* Adjust the values and color as needed */
  box-shadow: ;
}

.truncate-2-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines you want to display */
  -webkit-box-orient: vertical;  
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; /* Adjust based on your requirements */
}